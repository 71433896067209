import { createI18n } from "vue-i18n";
import _ from "lodash";
import zh from "./lang/zh_cn";
import tw from "./lang/zh_tw";
import en from "./lang/en_us";
import ja from "./lang/ja_jp";
import ko from "./lang/ko_kr";
import vi from "./lang/vi_vn";
import th from "./lang/th_th";
import ms from "./lang/ms_my";
const availableLocales = [
  "zh-CN",
  "zh-TW",
  "en-US",
  "ja-JP",
  "ko-KR",
  "vi-VN",
  "th-TH",
  "ms-MY",
];
const defaultLang = "en";
const localeMapping = {
  "zh-CN": "zh",
  "zh-TW": "tw",
  "en-US": "en",
  "ja-JP": "ja",
  "ko-KR": "ko",
  "vi-VN": "vi",
  "th-TH": "th",
  "ms-MY": "ms",
};

// Function to get the locale parameter from either search or hash
function getLocaleFromUrl() {
  const searchParams = new URLSearchParams(window.location.search);
  const hashParams = new URLSearchParams(
    window.location.hash.includes("?") ? window.location.hash.split("?")[1] : ""
  );

  // Check search first, then hash
  return searchParams.get("lang") || hashParams.get("lang");
}

const queryLocale = getLocaleFromUrl();

console.log("Query locale:", queryLocale);
console.log("localStorage locale:", localStorage.getItem("locale"));

let locale;
if (_.includes(availableLocales, queryLocale)) {
  locale = localeMapping[queryLocale];
  localStorage.setItem("locale", locale);
} else if (localStorage.getItem("locale") != null) {
  locale = localStorage.getItem("locale");
} else {
  locale = defaultLang;
}
console.log("locale locale:", locale);

switch (locale) {
  case "zh":
    document.title = "公私钥生成器 | Poly Wallet";
    break;
  case "tw":
    document.title = "公私鑰產生器 | Poly Wallet";
    break;
  case "en":
    document.title = "Key Generator | Poly Wallet";
    break;
  case "ja":
    document.title = "キージェネレーター | Poly Wallet";
    break;
  case "ko":
    document.title = "키 생성기 | Poly Wallet";
    break;
  case "vi":
    document.title = "Trình tạo khóa riêng | Poly Wallet";
    break;
  case "th":
    document.title = "เครื่องกำเนิดคีย์ส่วนตัว | Poly Wallet";
    break;
  case "ms":
    document.title = "Penjana kunci awam dan persendirian | Poly Wallet";
    break;
  default:
    break;
}

const i18n = createI18n({
  locale,
  messages: {
    zh,
    tw,
    en,
    ja,
    ko,
    vi,
    th,
    ms,
  },
});
export default function (app) {
  app.use(i18n);
}
