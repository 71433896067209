export default {
  product: {
    title: "Sản phẩm",
    selfCustodyWallet: "Ví di động",
    selfCustodyWalletDesc:
      "Ví tài sản số trên thiết bị di động của bạn, hỗ trợ iOS và Android",
    hardwareWallet: "Ví cứng",
    hardwareWalletDesc: "Sở hữu KeyPal để bảo mật tài sản của bạn hơn",
    extensionWallet: "Ví mở rộng",
    extensionWalletDesc: "Ví tốt hơn trên máy tính của bạn",
    transit: "Transit",
    transitDesc: "Công cụ tổng hợp đa chuỗi, xuyên chuỗi và thị trường NFT",
    fiveDegrees: "5Degrees",
    fiveDegreesDesc: "Giao thức mạng xã hội trên Web3.0",
  },
  project: {
    subDApp: "Gửi DApp",
    subToken: "Gửi token",
    subNFT: "Gửi NFT",
    subChain: "Gửi chuỗi công khai",
  },
  assets: {
    title: "Tài sản",
  },
  collaborations: {
    title: "Hợp tác dự án",
  },
  community: {
    title: "Cộng đồng",
    developers: "Nhà phát triển",
    recruiting: "TP Hiệp sĩ",
  },
  helpCenter: {
    title: "Trung tâm trợ giúp",
  },
  features: {
    title: "Tính năng",
    buyCrypto: "Mua tài sản mã hóa",
    mobileWallet: "Ví di động",
    hardwareWallet: "Ví cứng",
    extensionWallet: "Ví mở rộng",
    desktop: "Ví trên máy tính",
    fiveDegrees: "5Degrees",
    approvalDetector: "Phát hiện phê duyệt",
    tokenSecurity: "Kiểm tra bảo mật token",
    keyGenerator: "Trình tạo khóa riêng",
    BATCH_SENDER: "Chuyển tiền hàng loạt",
  },
  information: {
    title: "Thông tin",
    blockchainGuide: "Sách nhỏ về blockchain",
    tronWallet: "Sách nhỏ về Tron",
    iostWallet: "Sách nhỏ về IOST",
    tpMan: "TP Hiệp sĩ",
  },
  developers: {
    title: "Nhà phát triển",
    github: "Github (TP-Lab)",
    devCenter: "Trung tâm phát triển",
    subDApp: "Gửi DApp",
    subToken: "Gửi token",
    subNFT: "Gửi NFT",
  },
  company: {
    title: "Công ty",
    about: "Giới thiệu",
    careers: "Tuyển dụng",
    pressKit: "Tài liệu truyền thông",
    swagShop: "Cửa hàng hàng hóa",
  },
  support: {
    title: "Hỗ trợ",
    helpCenter: "Trung tâm trợ giúp",
    contactUs: "Liên hệ với chúng tôi",
  },
  legal: {
    title: "Pháp lý",
    privacyPolicy: "Chính sách quyền riêng tư",
    terms: "Thỏa thuận dịch vụ",
  },
  chain: {
    btc: "Bitcoin",
    ethereum: "Ethereum / EVMs",
    dogecoin: "Dogecoin",
    eos: "EOS",
    iost: "IOST",
    tron: "Tron",
    cosmos: "COSMOS",
    binance: "Binance",
    nervos: "Nervos",
    jingtum: "Kính thông",
    solana: "Solana",
    aptos: "Aptos",
    polkadot: "Polkadot",
    bch: "BCH",
    ltc: "LTC",
    conflux: "Conflux",
    nostr: "Nostr",
    sui: "Sui",
  },
  main: {
    title: "Trình tạo khóa công tư",
    language: "Tiếng Trung",
    intro:
      "Công cụ này dùng để tạo ngẫu nhiên khóa công và khóa riêng cho các mạng, quá trình tạo có thể thực hiện ngoại tuyến, mã nguồn của trình tạo đã được mở, bạn có thể xem và xác minh",
    tips: "Chúng tôi khuyên bạn nên tạo khóa công và khóa riêng ngoại tuyến, sau khi tạo hãy chắc chắn ghi lại và bảo quản thông tin khóa riêng của bạn, không tiết lộ hoặc công khai thông tin khóa riêng của bạn cho bất kỳ ai!",
    githubUrl: "Địa chỉ mã nguồn mở",
    publicKey: "Địa chỉ",
    privateKey: "Khóa riêng",
    gen: "Tạo ngẫu nhiên",
    copy: "Sao chép",
    copied: "Đã sao chép",
    failed: "Sao chép thất bại",
    normalAddr: "Địa chỉ thông thường",
    segwitAddr: "Địa chỉ SegWit",
    address: "Địa chỉ",
    changeChain: "Chuyển đổi nền tảng",
  },
  follow: {
    title: "Theo dõi chúng tôi",
    desc1:
      "Dịch vụ khách hàng của Poly Wallet sẽ không nhắn tin riêng cho bạn!",
    desc2:
      "Xin lưu ý, bạn sắp vào cộng đồng Poly Wallet, có thể có người giả mạo nhân viên của Poly Wallet nhắn tin riêng cho bạn, hãy luôn cảnh giác, mọi người nhắn tin riêng cho bạn có thể là kẻ lừa đảo! Nhân viên Poly Wallet sẽ không chủ động nhắn tin riêng cho bạn!",
    desc3: "Đã hiểu, tiếp tục tiến tới",
  },
};
