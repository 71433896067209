export default {
  product: {
    title: "產品",
    selfCustodyWallet: "手機錢包",
    selfCustodyWalletDesc: "您的移動端數字資產錢包，支持iOS和Android",
    hardwareWallet: "硬件錢包",
    hardwareWalletDesc: "擁有KeyPal，讓您的資產更安全",
    extensionWallet: "插件錢包",
    extensionWalletDesc: "您電腦上更好用的錢包",
    transit: "Transit",
    transitDesc: "多鏈聚合、跨鏈工具及NFT市場",
    fiveDegrees: "5Degrees",
    fiveDegreesDesc: "Web3.0上的社交網絡協議",
  },
  project: {
    subDApp: "提交DApp",
    subToken: "提交代幣",
    subNFT: "提交NFT",
    subChain: "提交公鏈",
  },
  assets: {
    title: "資產",
  },
  collaborations: {
    title: "項目合作",
  },
  community: {
    title: "社區",
    developers: "開發者",
    recruiting: "TP俠",
  },
  helpCenter: {
    title: "幫助中心",
  },
  features: {
    title: "功能",
    buyCrypto: "購買加密資產",
    mobileWallet: "手機錢包",
    hardwareWallet: "硬件錢包",
    extensionWallet: "插件錢包",
    desktop: "桌面端錢包",
    fiveDegrees: "5Degrees",
    approvalDetector: "授權檢測",
    tokenSecurity: "代幣安全檢測",
    keyGenerator: "私鑰生成器",
    BATCH_SENDER: "批量轉賬",
  },
  information: {
    title: "資訊",
    blockchainGuide: "區塊鏈小白書",
    tronWallet: "波場小白書",
    iostWallet: "IOST小白書",
    tpMan: "TP俠",
  },
  developers: {
    title: "開發者",
    github: "Github (TP-Lab)",
    devCenter: "開發者中心",
    subDApp: "提交DApp",
    subToken: "提交代幣",
    subNFT: "提交NFT",
  },
  company: {
    title: "公司",
    about: "關於",
    careers: "招聘",
    pressKit: "品牌素材",
    swagShop: "周邊商店",
  },
  support: {
    title: "支持",
    helpCenter: "幫助中心",
    contactUs: "聯繫我們",
  },
  legal: {
    title: "法律",
    privacyPolicy: "隱私政策",
    terms: "服務協議",
  },
  chain: {
    btc: "比特幣",
    ethereum: "以太坊 / EVMs",
    dogecoin: "Dogecoin",
    eos: "EOS",
    iost: "IOST",
    tron: "波場",
    cosmos: "COSMOS",
    binance: "Binance",
    nervos: "Nervos",
    jingtum: "井通",
    solana: "Solana",
    aptos: "Aptos",
    polkadot: "波卡",
    bch: "BCH",
    ltc: "LTC",
    conflux: "Conflux",
    nostr: "Nostr",
    sui: "Sui",
  },
  main: {
    title: "公私鑰生成器",
    language: "中文",
    intro:
      "本工具用來隨機生成各網絡的公鑰私鑰，生成過程可斷網離線操作，生成器代碼已開源，您可前往查看並驗證",
    tips: "我們建議您進行斷網生成公鑰私鑰，生成後請一定要記錄並保管好您的私鑰信息，切勿向任何人透露、公開您的私鑰信息！",
    githubUrl: "開源地址",
    publicKey: "地址",
    privateKey: "私鑰",
    gen: "隨機生成",
    copy: "複製",
    copied: "已複製",
    failed: "複製失敗",
    normalAddr: "普通地址",
    segwitAddr: "隔離見證地址",
    address: "地址",
    changeChain: "切換底層",
  },
  follow: {
    title: "關注我們",
    desc1: "Poly Wallet 客服不會私聊您！",
    desc2:
      "請注意，您即將進入Poly Wallet社區，您進入社區可能會有人假冒Poly Wallet的工作人員私聊您，請您務必警惕，所有私聊您的都可能是騙子！Poly Wallet工作人員不會主動跟您私信！",
    desc3: "知道了，繼續前往",
  },
};
