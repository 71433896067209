export default {
  product: {
    title: "ผลิตภัณฑ์",
    selfCustodyWallet: "กระเป๋าสตางค์มือถือ",
    selfCustodyWalletDesc:
      "กระเป๋าสตางค์สินทรัพย์ดิจิทัลของคุณบนอุปกรณ์เคลื่อนที่ รองรับ iOS และ Android",
    hardwareWallet: "กระเป๋าสตางค์ฮาร์ดแวร์",
    hardwareWalletDesc: "มี KeyPal เพื่อทำให้สินทรัพย์ของคุณปลอดภัยยิ่งขึ้น",
    extensionWallet: "กระเป๋าสตางค์ส่วนขยาย",
    extensionWalletDesc: "กระเป๋าสตางค์ที่ใช้งานได้ดีกว่าบนคอมพิวเตอร์ของคุณ",
    transit: "Transit",
    transitDesc: "เครื่องมือการรวมหลายเชน, ข้ามเชน และตลาด NFT",
    fiveDegrees: "5Degrees",
    fiveDegreesDesc: "โปรโตคอลเครือข่ายสังคมบน Web3.0",
  },
  project: {
    subDApp: "ส่ง DApp",
    subToken: "ส่งโทเคน",
    subNFT: "ส่ง NFT",
    subChain: "ส่งเชนสาธารณะ",
  },
  assets: {
    title: "สินทรัพย์",
  },
  collaborations: {
    title: "ความร่วมมือโครงการ",
  },
  community: {
    title: "ชุมชน",
    developers: "นักพัฒนา",
    recruiting: "TP นักรบ",
  },
  helpCenter: {
    title: "ศูนย์ช่วยเหลือ",
  },
  features: {
    title: "ฟีเจอร์",
    buyCrypto: "ซื้อสินทรัพย์เข้ารหัส",
    mobileWallet: "กระเป๋าสตางค์มือถือ",
    hardwareWallet: "กระเป๋าสตางค์ฮาร์ดแวร์",
    extensionWallet: "กระเป๋าสตางค์ส่วนขยาย",
    desktop: "กระเป๋าสตางค์เดสก์ท็อป",
    fiveDegrees: "5Degrees",
    approvalDetector: "การตรวจสอบการอนุมัติ",
    tokenSecurity: "การตรวจสอบความปลอดภัยของโทเคน",
    keyGenerator: "เครื่องมือสร้างกุญแจส่วนตัว",
    BATCH_SENDER: "การส่งเงินจำนวนมาก",
  },
  information: {
    title: "ข้อมูล",
    blockchainGuide: "คู่มือเบื้องต้นเกี่ยวกับบล็อกเชน",
    tronWallet: "คู่มือเบื้องต้นเกี่ยวกับ Tron",
    iostWallet: "คู่มือเบื้องต้นเกี่ยวกับ IOST",
    tpMan: "TP นักรบ",
  },
  developers: {
    title: "นักพัฒนา",
    github: "Github (TP-Lab)",
    devCenter: "ศูนย์นักพัฒนา",
    subDApp: "ส่ง DApp",
    subToken: "ส่งโทเคน",
    subNFT: "ส่ง NFT",
  },
  company: {
    title: "บริษัท",
    about: "เกี่ยวกับ",
    careers: "รับสมัครงาน",
    pressKit: "ชุดสื่อ",
    swagShop: "ร้านค้าสินค้า",
  },
  support: {
    title: "สนับสนุน",
    helpCenter: "ศูนย์ช่วยเหลือ",
    contactUs: "ติดต่อเรา",
  },
  legal: {
    title: "กฎหมาย",
    privacyPolicy: "นโยบายความเป็นส่วนตัว",
    terms: "ข้อตกลงการให้บริการ",
  },
  chain: {
    btc: "บิตคอยน์",
    ethereum: "อีเธอเรียม / EVMs",
    dogecoin: "ด็อกคอยน์",
    eos: "EOS",
    iost: "IOST",
    tron: "ทรอน",
    cosmos: "COSMOS",
    binance: "Binance",
    nervos: "Nervos",
    jingtum: "จิงตง",
    solana: "Solana",
    aptos: "Aptos",
    polkadot: "Polkadot",
    bch: "BCH",
    ltc: "LTC",
    conflux: "Conflux",
    nostr: "Nostr",
    sui: "Sui",
  },
  main: {
    title: "เครื่องมือสร้างกุญแจสาธารณะและส่วนตัว",
    language: "ภาษาไทย",
    intro:
      "เครื่องมือนี้ใช้เพื่อสร้างกุญแจสาธารณะและส่วนตัวแบบสุ่มสำหรับเครือข่ายต่าง ๆ กระบวนการสร้างสามารถทำได้แบบออฟไลน์ โค้ดของเครื่องมือสร้างได้เปิดเผยแล้ว คุณสามารถไปดูและตรวจสอบได้",
    tips: "เราแนะนำให้คุณสร้างกุญแจสาธารณะและส่วนตัวแบบออฟไลน์ หลังจากสร้างแล้วโปรดบันทึกและรักษาข้อมูลกุญแจส่วนตัวของคุณให้ดี ห้ามเปิดเผยหรือทำให้ข้อมูลกุญแจส่วนตัวของคุณกลายเป็นสาธารณะ!",
    githubUrl: "ที่อยู่โค้ดโอเพนซอร์ส",
    publicKey: "ที่อยู่",
    privateKey: "กุญแจส่วนตัว",
    gen: "สร้างสุ่ม",
    copy: "คัดลอก",
    copied: "คัดลอกเรียบร้อยแล้ว",
    failed: "คัดลอกล้มเหลว",
    normalAddr: "ที่อยู่ทั่วไป",
    segwitAddr: "ที่อยู่ SegWit",
    address: "ที่อยู่",
    changeChain: "เปลี่ยนพื้นฐาน",
  },
  follow: {
    title: "ติดตามเรา",
    desc1: "ฝ่ายบริการลูกค้าของ Poly Wallet จะไม่ติดต่อคุณเป็นการส่วนตัว!",
    desc2:
      "โปรดทราบว่าคุณกำลังจะเข้าสู่ชุมชน Poly Wallet อาจมีคนแอบอ้างเป็นพนักงานของ Poly Wallet ติดต่อคุณเป็นการส่วนตัว โปรดระมัดระวัง ทุกคนที่ติดต่อคุณอาจเป็นคนโกง! พนักงานของ Poly Wallet จะไม่ติดต่อคุณโดยตรง!",
    desc3: "เข้าใจแล้ว ไปต่อ",
  },
};
