export default {
  product: {
    title: "产品",
    selfCustodyWallet: "手机钱包",
    selfCustodyWalletDesc: "您的移动端数字资产钱包，支持iOS和Android",
    hardwareWallet: "硬件钱包",
    hardwareWalletDesc: "拥有KeyPal，让您的资产更安全",
    extensionWallet: "插件钱包",
    extensionWalletDesc: "您电脑上更好用的钱包",
    transit: "Transit",
    transitDesc: "多链聚合、跨链工具及NFT市场",
    fiveDegrees: "5Degrees",
    fiveDegreesDesc: "Web3.0上的社交网络协议",
  },
  project: {
    subDApp: "提交DApp",
    subToken: "提交代币",
    subNFT: "提交NFT",
    subChain: "提交公链",
  },
  assets: {
    title: "资产",
  },
  collaborations: {
    title: "项目合作",
  },
  community: {
    title: "社区",
    developers: "开发者",
    recruiting: "TP侠",
  },
  helpCenter: {
    title: "帮助中心",
  },
  features: {
    title: "功能",
    buyCrypto: "购买加密资产",
    mobileWallet: "手机钱包",
    hardwareWallet: "硬件钱包",
    extensionWallet: "插件钱包",
    desktop: "桌面端钱包",
    fiveDegrees: "5Degrees",
    approvalDetector: "授权检测",
    tokenSecurity: "代币安全检测",
    keyGenerator: "私钥生成器",
    BATCH_SENDER: "批量转账",
  },
  information: {
    title: "信息",
    blockchainGuide: "区块链小白书",
    tronWallet: "波场小白书",
    iostWallet: "IOST小白书",
    tpMan: "TP侠",
  },
  developers: {
    title: "开发者",
    github: "Github (TP-Lab)",
    devCenter: "开发者中心",
    subDApp: "提交DApp",
    subToken: "提交代币",
    subNFT: "提交NFT",
  },
  company: {
    title: "公司",
    about: "关于",
    careers: "招聘",
    pressKit: "品牌素材",
    swagShop: "周边商店",
  },
  support: {
    title: "支持",
    helpCenter: "帮助中心",
    contactUs: "联系我们",
  },
  legal: {
    title: "法律",
    privacyPolicy: "隐私政策",
    terms: "服务协议",
  },
  chain: {
    btc: "比特币",
    ethereum: "以太坊 / EVMs",
    dogecoin: "Dogecoin",
    eos: "EOS",
    iost: "IOST",
    tron: "波场",
    cosmos: "COSMOS",
    binance: "Binance",
    nervos: "Nervos",
    jingtum: "井通",
    solana: "Solana",
    aptos: "Aptos",
    polkadot: "波卡",
    bch: "BCH",
    ltc: "LTC",
    conflux: "Conflux",
    nostr: "Nostr",
    sui: "Sui",
  },
  main: {
    title: "公私钥生成器",
    language: "中文",
    intro:
      "本工具用来随机生成各网络的公钥私钥，生成过程可断网离线操作，生成器代码已开源，您可前往查看并验证",
    tips: "我们建议您进行断网生成公钥私钥，生成后请一定要记录并保管好您的私钥信息，切勿向任何人透露、公开您的私钥信息！",
    githubUrl: "开源地址",
    publicKey: "地址",
    privateKey: "私钥",
    gen: "随机生成",
    copy: "复制",
    copied: "已复制",
    failed: "复制失败",
    normalAddr: "普通地址",
    segwitAddr: "隔离见证地址",
    address: "地址",
    changeChain: "切换底层",
  },
  follow: {
    title: "关注我们",
    desc1: "Poly Wallet 客服不会私聊您！",
    desc2:
      "请注意，您即将进入Poly Wallet社区，您进入社区可能会有人假冒Poly Wallet的工作人员私聊您，请您务必警惕，所有私聊您的都可能是骗子！Poly Wallet工作人员不会主动跟您私信！",
    desc3: "知道了，继续前往",
  },
};
