export default {
  product: {
    title: "생산품",
    selfCustodyWallet: "모바일 지갑",
    selfCustodyWalletDesc: "블록체인 진입, 모바일 멀티체인 지갑",
    hardwareWallet: "하드웨어 지갑",
    hardwareWalletDesc: "키팔로 자산을 보호하고 안전하고 안심하세요.",
    extensionWallet: "플러그인 지갑",
    extensionWalletDesc: "너의 컴퓨터에 더 좋은 지갑을 놓아라",
    transit: "Transit",
    transitDesc: "멀티체인 DEX 애그리게이터 및 NFT 마켓플레이스 플랫폼",
    fiveDegrees: "5Degrees",
    fiveDegreesDesc: "Web3.0의 소셜 네트워크 프로토콜",
  },
  project: {
    subDApp: "DApp 체출하기",
    subToken: "토큰 제출하기",
    subNFT: "NFT 제출",
    subChain: "체인",
  },
  assets: {
    title: "자산",
  },
  collaborations: {
    title: "협업",
  },
  community: {
    title: "커뮤니티",
    developers: "개발자",
    recruiting: "모병",
  },
  helpCenter: {
    title: "도움",
  },
  features: {
    title: "기능",
    buyCrypto: "암호화폐 구매",
    mobileWallet: "모바일 지갑",
    hardwareWallet: "하드웨어 지갑",
    extensionWallet: "플러그인 지갑",
    desktop: "데스크탑 지갑",
    fiveDegrees: "5Degrees",
    approvalDetector: "감지 승인",
    tokenSecurity: "토큰 보안",
    keyGenerator: "열쇠 생성기",
    BATCH_SENDER: "일괄 송신자",
  },
  information: {
    title: "정보",
    blockchainGuide: "Blockchain 지갑 가이드라인",
    tronWallet: "TRON 지갑 가이드라인",
    iostWallet: "IOST 지갑 가이드라인",
    tpMan: "TP 맨",
  },
  developers: {
    title: "开发개발자者",
    github: "Github (TP-Lab)",
    devCenter: "개발자 센터",
    subToken: "토큰 제출하기",
    subDApp: "DApp 체출하기",
    subNFT: "NFT 제출",
  },
  company: {
    title: "회사",
    about: "에 대한",
    careers: "직업",
    pressKit: "프레스 키트",
    swagShop: "스웨그 샵",
  },
  support: {
    title: "서포트",
    helpCenter: "도움 센터",
    contactUs: "연락주세요",
  },
  legal: {
    title: "법률",
    privacyPolicy: "개인 정보 보호 정책",
    terms: "이용 약관",
  },
  chain: {
    btc: "BTC",
    ethereum: "ETH / EVMs",
    dogecoin: "Dogecoin",
    eos: "EOS",
    iost: "IOST",
    tron: "TRON",
    cosmos: "COSMOS",
    binance: "Binance",
    nervos: "Nervos",
    jingtum: "Jingtum",
    solana: "Solana",
    aptos: "Aptos",
    polkadot: "Polkadot",
    bch: "BCH",
    ltc: "LTC",
    conflux: "Conflux",
    nostr: "Nostr",
    sui: "Sui",
  },
  main: {
    title: "키 생성기",
    language: "한국인",
    intro:
      "이 도구는 각 네트워크의 공개키와 개인키를 무작위로 생성하는데 사용되며, 생성 과정은 네트워크와 오프라인에서 분리할 수 있으며, 생성기의 코드는 오픈소스로 공개되어 있어 확인 및 검증이 가능합니다.",
    tips: "공개키와 개인키를 생성하기 위해서는 네트워크 연결을 끊는 것이 좋습니다.생성 후에는 반드시 개인키 정보를 기록하여 보관하시기 바랍니다.개인키 정보를 누구에게도 공개하거나 공개하지 마십시오!",
    githubUrl: "오픈 소스 주소",
    publicKey: "주소",
    privateKey: "프라이빗 키",
    gen: "생성",
    copy: "복사",
    copied: "복사 완료!",
    failed: "복사 실패!",
    normalAddr: "일반 주소",
    segwitAddr: "세그윗 주소",
    address: "주소",
    changeChain: "하단 레이어 전환",
  },
  follow: {
    title: "우리를 따라오세요",
    desc1: "Poly Wallet 직원은 개인 메시지를 보내지 않습니다!",
    desc2:
      "주의! Poly Wallet 커뮤니티에 참여 중입니다. 누군가 우리를 사칭하여 개인 메시지를 보낼 수 있습니다! 개인 메시지를 보내는 사람은 모두 사기꾼일 수 있다는 점에 유의하세요! 우리는 당신에게 먼저 연락하지 않을 것입니다!",
    desc3: "이해, 입력",
  },
};
