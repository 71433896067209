export default {
  product: {
    title: "Product",
    selfCustodyWallet: "Mobile Wallet",
    selfCustodyWalletDesc: "Crypto&DeFi Mobile Wallet on Blockchain.",
    hardwareWallet: "Hardware Wallet",
    hardwareWalletDesc: "Get Your KeyPal, to Guard Your Assets.",
    extensionWallet: "Extension Wallet",
    extensionWalletDesc: "A Better Wallet on Your Computer.",
    transit: "Transit",
    transitDesc: "Multi-chain DEX Aggregator and NFT Marketplace Platform.",
    fiveDegrees: "5Degrees",
    fiveDegreesDesc: "Social Network Protocol in Web3.0.",
  },
  project: {
    subDApp: "Submit DApp",
    subToken: "Submit Token",
    subNFT: "Submit NFT",
    subChain: "Submit Chain",
  },
  assets: {
    title: "Assets",
  },
  collaborations: {
    title: "Collaborations",
  },
  community: {
    title: "Community",
    developers: "Developers",
    recruiting: "TP Man",
  },
  helpCenter: {
    title: "Help",
  },
  features: {
    title: "Features",
    buyCrypto: "Buy Crypto",
    mobileWallet: "Mobile Wallet",
    hardwareWallet: "Hardware Wallet",
    extensionWallet: "Extension Wallet",
    desktop: "Desktop Wallet",
    fiveDegrees: "5Degrees",
    approvalDetector: "Approval Detector",
    tokenSecurity: "Token Security",
    keyGenerator: "Key Generator",
    BATCH_SENDER: "BatchSender",
  },
  information: {
    title: "Information",
    blockchainGuide: "Blockchain Guide",
    tronWallet: "TRON Guide",
    iostWallet: "IOST Guide",
    tpMan: "TP Man",
  },
  developers: {
    title: "Developers",
    github: "Github (TP-Lab)",
    devCenter: "Dev Center",
    subToken: "Submit Token",
    subDApp: "Submit DApp",
    subNFT: "Submit NFT",
  },
  company: {
    title: "Company",
    about: "About",
    careers: "Careers",
    pressKit: "Press Kit",
    swagShop: "Swag Shop",
  },
  support: {
    title: "Support",
    helpCenter: "Help Center",
    contactUs: "Contact Us",
  },
  legal: {
    title: "Legal",
    privacyPolicy: "Privacy Policy",
    terms: "Terms of Use",
  },
  chain: {
    btc: "BTC",
    ethereum: "ETH / EVMs",
    dogecoin: "Dogecoin",
    eos: "EOS",
    iost: "IOST",
    tron: "TRON",
    cosmos: "COSMOS",
    binance: "Binance",
    nervos: "Nervos",
    jingtum: "Jingtum",
    solana: "Solana",
    aptos: "Aptos",
    polkadot: "Polkadot",
    bch: "BCH",
    ltc: "LTC",
    conflux: "Conflux",
    nostr: "Nostr",
    sui: "Sui",
  },
  main: {
    title: "Key Generator",
    language: "English",
    intro:
      "This tool is used to randomly generate public and private keys for various networks. The generation process can be done offline without an internet connection. The generator code has been open-sourced, and you can go and check it out and verify it.",
    tips: "We recommend that you generate your public and private keys offline. After generating the keys, please be sure to record and store your private key safely. Do not disclose private key information to anyone!",
    githubUrl: "Open Source",
    publicKey: "Address",
    privateKey: "Private Key",
    gen: "Generate",
    copy: "Copy",
    copied: "Copied!",
    failed: "Copy Failed!",
    normalAddr: "Normal Address",
    segwitAddr: "SegWit Address",
    address: "Address",
    changeChain: "Choose Network",
  },
  follow: {
    title: "Follow Us",
    desc1: "Poly Wallet Staffs won't send you private messages!",
    desc2:
      "Caution! You're entering Poly Wallet community, there might be someone impersonating us to send you private messages! Please be aware that, anyone who sends private messages might be a scammer! We will never contact you first!",
    desc3: "Understood, enter",
  },
};
