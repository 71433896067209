export default {
  product: {
    title: "Produk",
    selfCustodyWallet: "Dompet Mudah Alih",
    selfCustodyWalletDesc:
      "Dompet aset digital anda di peranti mudah alih, menyokong iOS dan Android",
    hardwareWallet: "Dompet Perkakasan",
    hardwareWalletDesc:
      "Miliki KeyPal untuk menjadikan aset anda lebih selamat",
    extensionWallet: "Dompet Sambungan",
    extensionWalletDesc: "Dompet yang lebih baik digunakan di komputer anda",
    transit: "Transit",
    transitDesc:
      "Alat agregasi pelbagai rangkaian, pemindahan silang dan pasaran NFT",
    fiveDegrees: "5Degrees",
    fiveDegreesDesc: "Protokol rangkaian sosial di Web3.0",
  },
  project: {
    subDApp: "Hantar DApp",
    subToken: "Hantar Token",
    subNFT: "Hantar NFT",
    subChain: "Hantar Rangkaian Awam",
  },
  assets: {
    title: "Aset",
  },
  collaborations: {
    title: "Kerjasama Projek",
  },
  community: {
    title: "Komuniti",
    developers: "Pembangun",
    recruiting: "TP Warrior",
  },
  helpCenter: {
    title: "Pusat Bantuan",
  },
  features: {
    title: "Ciri",
    buyCrypto: "Beli Aset Kripto",
    mobileWallet: "Dompet Mudah Alih",
    hardwareWallet: "Dompet Perkakasan",
    extensionWallet: "Dompet Sambungan",
    desktop: "Dompet Desktop",
    fiveDegrees: "5Degrees",
    approvalDetector: "Pengesan Kelulusan",
    tokenSecurity: "Pengesanan Keselamatan Token",
    keyGenerator: "Penjana Kunci Peribadi",
    BATCH_SENDER: "Penghantaran Secara Berkelompok",
  },
  information: {
    title: "Maklumat",
    blockchainGuide: "Panduan Blockchain untuk Pemula",
    tronWallet: "Panduan Tron untuk Pemula",
    iostWallet: "Panduan IOST untuk Pemula",
    tpMan: "TP Warrior",
  },
  developers: {
    title: "Pembangun",
    github: "Github (TP-Lab)",
    devCenter: "Pusat Pembangun",
    subDApp: "Hantar DApp",
    subToken: "Hantar Token",
    subNFT: "Hantar NFT",
  },
  company: {
    title: "Syarikat",
    about: "Tentang",
    careers: "Peluang Kerjaya",
    pressKit: "Kit Media",
    swagShop: "Kedai Barangan",
  },
  support: {
    title: "Sokongan",
    helpCenter: "Pusat Bantuan",
    contactUs: "Hubungi Kami",
  },
  legal: {
    title: "Undang-undang",
    privacyPolicy: "Dasar Privasi",
    terms: "Perjanjian Perkhidmatan",
  },
  chain: {
    btc: "Bitcoin",
    ethereum: "Ethereum / EVMs",
    dogecoin: "Dogecoin",
    eos: "EOS",
    iost: "IOST",
    tron: "Tron",
    cosmos: "COSMOS",
    binance: "Binance",
    nervos: "Nervos",
    jingtum: "Jingtum",
    solana: "Solana",
    aptos: "Aptos",
    polkadot: "Polkadot",
    bch: "BCH",
    ltc: "LTC",
    conflux: "Conflux",
    nostr: "Nostr",
    sui: "Sui",
  },
  main: {
    title: "Penjana Kunci Awam dan Peribadi",
    language: "Bahasa Melayu",
    intro:
      "Alat ini digunakan untuk menjana kunci awam dan peribadi secara rawak untuk pelbagai rangkaian. Proses menjana boleh dilakukan secara luar talian. Kod penjana telah dibuka dan anda boleh pergi melihat dan mengesahkan.",
    tips: "Kami mengesyorkan anda menjana kunci awam dan peribadi secara luar talian. Setelah menjana, sila catat dan simpan maklumat kunci peribadi anda dengan baik. Jangan sekali-kali mendedahkan atau menjadikan maklumat kunci peribadi anda terbuka!",
    githubUrl: "Alamat sumber terbuka",
    publicKey: "Alamat",
    privateKey: "Kunci Peribadi",
    gen: "Jana Secara Rawak",
    copy: "Salin",
    copied: "Telah Disalin",
    failed: "Salinan Gagal",
    normalAddr: "Alamat Biasa",
    segwitAddr: "Alamat SegWit",
    address: "Alamat",
    changeChain: "Tukar Rangkaian",
  },
  follow: {
    title: "Ikuti Kami",
    desc1:
      "Perkhidmatan pelanggan Poly Wallet tidak akan menghubungi anda secara peribadi!",
    desc2:
      "Sila ambil perhatian bahawa anda akan memasuki komuniti Poly Wallet. Mungkin ada orang yang menyamar sebagai kakitangan Poly Wallet menghubungi anda secara peribadi. Sila berhati-hati, semua yang menghubungi anda mungkin adalah penipu! Kakitangan Poly Wallet tidak akan menghubungi anda secara peribadi!",
    desc3: "Faham, teruskan",
  },
};
